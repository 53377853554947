<template>
	<div class="data-form-wrapper container">
		<div class="head">
			<h2>Daten</h2>
			<div class="xml-wrapper">
				<label for="xmlNumber">XML-Nummer:</label>
				<input
					id="xmlNumber"
					type="text"
					v-model="data.xmlNumber"
					autocomplete="off"
				/>
			</div>
		</div>
		<div class="general-info">
			<div class="order">
				<label for="order"
					>Auftrag:*<br /><span class="has-error" v-if="$v.data.order.$error"
						>Auftrag muss gefüllt sein!</span
					></label
				>
				<textarea
					id="order"
					rows="5"
					v-model="$v.data.order.$model"
					autocomplete="off"
				></textarea>
			</div>
			<div class="color">
				<label for="teethcolor"
					>Zahnfarbe:*<br /><span class="has-error" v-if="$v.data.color.$error"
						>Zahnfarbe muss gefüllt sein!</span
					></label
				>
				<input
					id="teethcolor"
					v-model="$v.data.color.$model"
					type="text"
					autocomplete="off"
				/>
			</div>
			<div class="material">
				<label for="material">Material:</label>
				<input id="material" v-model="data.material" type="text" />
			</div>
			<div class="consultation">
				<label>Rücksprache notwendig:</label>
				<div class="buttons">
					<input
						type="radio"
						id="consultradioYes"
						name="radioConsultation"
						value="1"
						v-model="data.callBack"
					/>
					<label for="consultradioYes">Ja</label>

					<input
						type="radio"
						id="consultradioNo"
						name="radioConsultation"
						value="0"
						v-model="data.callBack"
					/>
					<label for="consultradioNo">Nein</label>
				</div>
			</div>
			<div class="offer">
				<label>Kostenvoranschlag erwünscht:</label>
				<div class="buttons">
					<input
						type="radio"
						id="offerradioYes"
						name="radioOffer"
						value="1"
						v-model="data.offer"
					/>
					<label for="offerradioYes">Ja</label>

					<input
						type="radio"
						id="offerradioNo"
						name="radioOffer"
						value="0"
						v-model="data.offer"
					/>
					<label for="offerradioNo">Nein</label>
				</div>
			</div>
		</div>
		<div class="second-head">
			<h2>Fertigstellung</h2>
		</div>
		<div
			class="error"
			v-if="$v.data.firstDateType.$error || $v.data.secondDateType.$error"
		>
			<span>Mindestens ein Datum muss die Fertigstellung beinhalten!</span
			><br /><span>Fertgistellung muss das letzte Datum sein.</span>
		</div>
		<div class="error" v-if="$v.data.firstDate.$error">
			<span
				>Der 1. Termin muss gesetzt sein und vor allen anderen Terminen
				liegen!</span
			>
		</div>
		<div class="error" v-if="$v.data.secondDate.$error">
			<span>Der 2. Termin muss gesetzt sein und vor dem 3. Termin liegen!</span>
		</div>
		<div class="error" v-if="$v.data.thirdDate.$error">
			<span>Der 3. Termin muss gesetzt sein!</span>
		</div>
		<div class="dates">
			<div class="first-date">
				<label for="firstDate"
					>1. Termin<span v-if="data.offer === '0'">*</span></label
				>
				<vc-date-picker
					class="datepicker-wrapper"
					v-model="$v.data.firstDate.$model"
					:input-debounce="2000"
					:min-date="new Date(Date.now() + 86400000 * 1)"
					:disabled-dates="{ weekdays: [1, 7] }"
					mode="dateTime"
					:timezone="'Europe/Berlin'"
					:is24hr="true"
				>
					<template v-slot="{ inputValue, inputEvents }">
						<div class="datepicker">
							<button class="button toggle-date" disabled>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
									<path
										d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
									></path>
								</svg>
							</button>
							<input
								:value="inputValue"
								v-on="inputEvents"
								placeholder="Datum auswählen"
								readonly
							/>
						</div>
					</template>
				</vc-date-picker>
				<select v-model="$v.data.firstDateType.$model">
					<option>1. Anprobe</option>
					<option>2. Anprobe</option>
					<option>Fertigstellung</option>
				</select>
			</div>
			<div class="second-date">
				<label for="secondDate"
					>2. Termin<span
						v-if="
							data.firstDateType !== 'Fertigstellung' &&
							data.firstDateType !== '' &&
							data.offer === '0'
						"
						>*</span
					></label
				>
				<vc-date-picker
					class="datepicker-wrapper"
					v-model="$v.data.secondDate.$model"
					:input-debounce="2000"
					:min-date="nextSecondDate"
					:disabled-dates="{ weekdays: [1, 7] }"
					mode="dateTime"
					:timezone="'Europe/Berlin'"
					:is24hr="true"
				>
					<template v-slot="{ inputValue, inputEvents }">
						<div class="datepicker">
							<button class="button toggle-date" disabled>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
									<path
										d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
									></path>
								</svg>
							</button>
							<input
								:value="inputValue"
								v-on="inputEvents"
								placeholder="Datum auswählen"
								readonly
							/>
						</div>
					</template>
				</vc-date-picker>
				<select v-model="$v.data.secondDateType.$model">
					<option>2. Anprobe</option>
					<option>Fertigstellung</option>
				</select>
			</div>
			<div class="third-date">
				<unicon
					name="plus-circle"
					:fill="'#023c40'"
					icon-style="line"
					width="24"
					height="24"
					v-if="!activateThirdDate"
					@click="activateThirdDate = !activateThirdDate"
				/>
				<label for="thirdDate" v-if="activateThirdDate">3. Termin</label>

				<vc-date-picker
					class="datepicker-wrapper"
					v-if="activateThirdDate"
					v-model="$v.data.thirdDate.$model"
					:input-debounce="2500"
					:min-date="nextThirdDate"
					:disabled-dates="{ weekdays: [1, 7] }"
					mode="dateTime"
					:timezone="'Europe/Berlin'"
					:is24hr="true"
				>
					<template v-slot="{ inputValue, inputEvents }">
						<div class="datepicker">
							<button class="button toggle-date" disabled>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
									<path
										d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
									></path>
								</svg>
							</button>
							<input
								:value="inputValue"
								v-on="inputEvents"
								placeholder="Datum auswählen"
								readonly
							/>
						</div>
					</template>
				</vc-date-picker>
				<select v-if="activateThirdDate" v-model="data.thirdDateType">
					<option>Fertigstellung</option>
				</select>
			</div>
			<div class="delivery">
				<input
					type="radio"
					name="delivery"
					id="abholung"
					value="Abholung"
					v-model="data.delivery"
				/>
				<label for="abholung">Abholung</label>
				<input
					type="radio"
					name="delivery"
					id="Versand"
					value="Versand"
					v-model="data.delivery"
				/>
				<label for="Versand">Versand</label>
			</div>
			<div class="send">
				<button class="button" type="submit" @click="startUpload">
					Upload Starten
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import moment from 'moment';

function firstDateTypeValid(value) {
	this.$v.data.firstDate.$touch();
	if (this.data.offer === '1') {
		return true;
	} else {
		if (value === 'Fertigstellung') {
			return this.data.secondDateType === '' && this.data.thirdDate === '';
		} else {
			return (
				this.data.secondDateType === 'Fertigstellung' ||
				(this.data.thirdDateType === 'Fertigstellung' &&
					this.data.thirdDate !== '')
			);
		}
	}
}
function secondDateTypeValid(value) {
	this.$v.data.secondDate.$touch();
	if (this.data.offer === '1') {
		return true;
	} else {
		if (value === 'Fertigstellung') {
			return (
				this.data.firstDateType !== 'Fertigstellung' &&
				this.data.thirdDate === ''
			);
		} else {
			return (
				this.data.firstDateType === 'Fertigstellung' ||
				(this.data.thirdDateType === 'Fertigstellung' &&
					this.data.thirdDate !== '')
			);
		}
	}
}

function firstDateValid(value) {
	if (this.data.secondDate === '' && this.data.thirdDate === '') {
		return true;
	} else if (this.data.secondDate !== '' && this.data.thirdDate === '') {
		return moment(this.data.secondDate).isAfter(moment(value));
	} else if (this.data.secondDate !== '' && this.data.thirdDate !== '') {
		return (
			moment(this.data.secondDate).isAfter(moment(value)) &&
			moment(this.data.thirdDate).isAfter(moment(value))
		);
	}
}

function secondDateValid(value) {
	if (this.data.thirdDate === '') {
		return true;
	} else if (this.data.thirdDate !== '') {
		return moment(this.data.thirdDate).isAfter(moment(value));
	}
}

export default {
	data() {
		return {
			activateThirdDate: false,
			data: {
				xmlNumber: '',
				color: '',
				material: '',
				order: '',
				callBack: '0',
				firstDate: '',
				secondDate: '',
				thirdDate: '',
				firstDateType: '',
				secondDateType: '',
				thirdDateType: 'Fertigstellung',
				delivery: 'Abholung',
				offer: '0'
			}
		};
	},
	computed: {
		nextSecondDate() {
			const r =
				this.data.firstDate === ''
					? new Date()
					: moment(this.data.firstDate, 'DD.MM.YYYY').add(1, 'days').toDate();
			return new Date();
		},
		nextThirdDate() {
			const r =
				this.data.secondDate === ''
					? new Date()
					: moment(this.data.secondDate, 'DD.MM.YYYY').add(1, 'days').toDate();
			return new Date();
		}
	},
	validations: {
		data: {
			color: {
				required
			},
			order: {
				required
			},
			firstDate: {
				firstDateValid,
				required: requiredIf(function () {
					return this.data.offer === '0';
				})
			},
			firstDateType: {
				firstDateTypeValid,
				required: requiredIf(function () {
					return this.data.offer === '0';
				})
			},
			secondDate: {
				secondDateValid,
				required: requiredIf(function () {
					return (
						this.data.firstDateType !== 'Fertigstellung' &&
						this.data.offer === '0'
					);
				})
			},
			secondDateType: {
				secondDateTypeValid,
				required: requiredIf(function () {
					return (
						this.data.firstDateType !== 'Fertigstellung' &&
						this.data.offer === '0'
					);
				})
			},
			thirdDate: {
				required: requiredIf(function () {
					return (
						this.data.firstDateType !== 'Fertigstellung' &&
						this.data.secondDateType !== 'Fertigstellung' &&
						this.data.firstDateType !== '' &&
						this.data.secondDateType !== '' &&
						this.data.offer === '0'
					);
				})
			}
		}
	},
	methods: {
		startUpload() {
			this.$emit('upload');
		},
		checkForErrors() {
			this.$v.data.$touch();
			if (!this.$v.$anyError) {
				return this.data;
			} else {
				return false;
			}
		},
		reset() {
			this.data = {
				xmlNumber: '',
				color: '',
				material: '',
				order: '',
				callBack: '0',
				firstDate: '',
				secondDate: '',
				thirdDate: '',
				firstDateType: '',
				secondDateType: '',
				thirdDateType: 'Fertigstellung',
				delivery: 'Abholung',
				offer: '0'
			};
			this.activateThirdDate = false;
			this.$v.$reset();
		}
	}
};
</script>

<style lang="scss">
.data-form-wrapper {
	margin-left: 1rem;
	margin-top: 1rem;
	padding: 0.5rem 0;
	.error {
		padding-left: 1rem;
		color: $background-nok;
		text-decoration: underline;
	}
	.head {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		.xml-wrapper {
			display: grid;
			width: 50%;
			grid-template-columns: 1fr 2fr;
			align-items: center;
		}
	}
	.second-head {
		margin-top: 1rem;
		display: flex;
		align-items: center;
	}
	.general-info {
		padding: 0.5rem 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto auto auto;
		grid-row-gap: 1rem;
		.has-error {
			label {
				text-decoration: underline;
				color: $background-nok;
			}
			textarea {
				background: rgba($background-nok, 0.75);
			}
		}
		.order {
			grid-column: 1 / 3;
			grid-row: 1;
			display: grid;
			grid-template-columns: 1fr 3fr;
			align-items: center;
			label {
				text-align: center;
				font-weight: 600;
			}
			textarea {
				resize: vertical;
			}
		}
		.color {
			grid-column: 1;
			grid-row: 2;
		}
		.material {
			grid-column: 1;
			grid-row: 3;
		}
		.consultation {
			grid-column: 2;
			grid-row: 2;
		}
		.offer {
			grid-column: 2;
			grid-row: 3;
		}
		.color,
		.material {
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: center;
			justify-content: center;
			text-align: center;
		}
		.consultation,
		.offer {
			display: flex;
			align-items: center;
			gap: 1rem;
			justify-content: flex-end;
		}
		.offer,
		.consultation {
			label {
				margin: 0;
			}
			.buttons {
				align-items: center;
				margin: auto 0;
				input[type='radio'] {
					opacity: 0;
					position: fixed;
					width: 0;
					&:checked + label {
						background-color: $primaryColor;
						color: white;
					}
				}
				label {
					background-color: white;
					display: inline-block;
					padding: 0.33rem 0.5rem;
					border: 1px solid $text-color;
					margin: 0;
					&:hover {
						background-color: $primaryColor;
						color: white;
					}
					&:first-of-type {
						border-radius: 3px 0 0 3px;
						border-right: none;
					}
					&:last-of-type {
						border-radius: 0 3px 3px 0;
						border-left: none;
					}
				}
			}
		}
	}
	.dates {
		display: grid;
		grid-template-columns: 3fr 2fr;
		grid-template-rows: 1fr 1fr 1fr;
		margin-top: 1rem;
		&.has-error {
			div:not(.delivery) {
				label {
					text-decoration: underline;
					color: $background-nok;
				}
				input {
					background: rgba($background-nok, 0.75);
				}
			}
		}
		.datepicker-wrapper {
			height: 100%;
			display: inline-block;
			width: 75%;
			.datepicker {
				display: flex;
				align-items: center;
				height: 100%;
				.toggle-date {
					padding: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0;
					height: 100%;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					&:disabled {
						cursor: default;
					}
					svg {
						height: 1rem;
						path {
							fill: white;
						}
					}
				}

				input {
					height: 100%;
					width: 100%;
					padding: 0 5px;
				}
			}
			.vc-popover-content {
				select {
					width: 100% !important;
				}
			}
		}
		.first-date {
			grid-column: 1;
			grid-row: 1;
			margin: 0.5rem;
			display: grid;
			grid-template-columns: 1fr 2fr 2fr;
			align-items: center;
			text-align: center;
			input,
			select {
				width: 75%;
				height: 100%;
			}
			.vdp-datepicker {
				height: 100%;
				div:first-child {
					height: 100%;
				}
			}
		}
		.second-date {
			grid-column: 1;
			grid-row: 2;
			margin: 0.5rem;
			display: grid;
			grid-template-columns: 1fr 2fr 2fr;
			align-items: center;
			text-align: center;
			input,
			select {
				width: 75%;
				height: 100%;
			}
			.vdp-datepicker {
				height: 100%;
				div:first-child {
					height: 100%;
				}
			}
		}
		.third-date {
			grid-column: 1;
			grid-row: 3;
			margin: 0.5rem;
			display: grid;
			grid-template-columns: 1fr 2fr 2fr;
			align-items: center;
			text-align: center;
			input,
			select {
				width: 75%;
				height: 100%;
			}
			.vdp-datepicker {
				height: 100%;
				div:first-child {
					height: 100%;
				}
			}
			.unicon {
				max-width: fit-content;
				justify-self: center;
			}
		}
		.delivery {
			justify-content: flex-end;
			display: flex;
			align-items: center;
			input {
				width: auto;
				margin: 0 1rem;
			}
		}
		.send {
			justify-content: flex-end;
			display: flex;
			align-items: center;
		}
		@media (max-width: 720px) {
			.first-date,
			.second-date,
			.third-date {
				grid-template-columns: 1fr 1fr;
				grid-template-rows: 1fr 1fr;
				justify-items: center;
				label {
					grid-row: 1;
					grid-column: 1;
				}
				input {
					grid-row: 1;
					grid-column: 2;

					justify-self: flex-start;
				}
				select {
					margin-top: 0.5rem;
					grid-row: 2;
					grid-column: 1 / 3;
				}
			}
		}
	}
}
</style>
